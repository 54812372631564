import { PAGE_URL } from "./routes";

export const DISEASE_SPECIFIC_INSIGHTS_TASKS: ITask[] = [
  {
    title: "Oncology - Stage extraction",
    description:
      "Extract Stage of Cancer from text or perform TNM Staging calculation from multi-modal data",
    link: "",
  },
  {
    title: "Oncology - Outcome prediction",
    description:
      "Predict outcome of therapy line, based on patient history, histology and treatment history",
    link: "",
  },
  {
    title: "Oncology - Gene expression extraction",
    description: "Identify Gene expressions and mutations linked to different cancer types",
    link: "",
  },
  {
    title: "Oncology - Lab values extraction",
    description: "Detect and plot lab values like TPS scores, PDL-1 expression from lab reports",
    link: "",
  },
  {
    title: "Hemophilia - Biomedical Question Answering",
    description: "Contextual question answering task for Hemophilia datasets",
    link: `${PAGE_URL.DISEASE_SPECIFIC_INSIGHTS}${PAGE_URL.HEMOPHILIA_QUESTION_ANSWERING}`,
  },
  {
    title: "CNS - PD biomarker extraction",
    description: "Biomarker extraction from multimodal data to map to disease progression",
    link: "",
  },
];
