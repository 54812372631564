export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/create-password",
  CREATE_PASSWORD_SUCCESS: "/create-password-success",
  DASHBOARD: "/dashboard",
  PROJECT_DETAIL: "/project/detail",
  SEARCH: "/search",
  DATASETS: "/datasets",
  TASKS: "/tasks",
  TASKS_CLINICAL_DATA: "/clinical-data-tasks",
  GENERATIVE_AI_TASKS: "/generative-ai-tasks",
  DATA_EXTRACTION_AND_NORMALIZATIONTASKS: "/data-extraction-and-normalization-tasks",
  DISEASE_SPECIFIC_INSIGHTS: "/disease-specific-insights",
  SEARCH_AND_DATA_MINING_TASKS: "/search-and-data-mining-tasks",
  AUTOCOMPLETE: "/autocomplete",
  CLNICAL_NOTE_CLASSIFICATION: "/clinical-note-classification",
  CLINICAL_RESEARCH_TASKS: "/clinical-research-tasks",
  CLINICAL_PROTOCOL_SUMMARY: "/clinical-protocol-summary",
  CLINICAL_PROTOCOL_ICEC_SUMMARY: "/clinical-protocol-icec-summary",
  CLINICAL_ENTITY_EXTRACTION: "/clinical-entity-extraction",
  CLINICAL_ENTITY_NORMALIZATION: "/clinical-entity-normalization",
  CLINICAL_NOTE_CLASSIFICATION: "/clinical-note-classification",
  HEIDIS_INSIGHT: "/hedis-insights",
  REALTION_EXTRACTION: "/relation-extraction",
  IDENTIFY_MEDICAL_ENTITIES: "/identify-medical-entites",
  IDENTIFY_RELATIONSHIP: "/identify-relationship",
  DOCUMENT_QUESTION_ANSWERING: "/document-question-answering",
  HEMOPHILIA_QUESTION_ANSWERING: "/hempophilia-question-answering",
  APPLICATIONS: "/copilots",
  GENERATIVE_QA: "/generative-qa",
  PHI_REDACTION: "/phi_redaction",
  LABORATORY_RESULT: "/clinical-data-tasks/laboratory-result",
  FORM_2728_PROCESSOR: "/clinical-data-tasks/form_2728_processor",
  PATIENT_PROFILE: "/subject",
  EVIDENCE_VIEWER: "/evidence",
  DOCUMENT_INSIGHTS: "/document-insights"
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DASHBOARD, title: "Dashboard", displayBackButton: false },
  { route: PAGE_URL.PROJECT_DETAIL, title: "Project Details", displayBackButton: true },
  {
    route: PAGE_URL.SEARCH,
    title: "Search",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.DATASETS,
    title: "Datasets",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.TASKS,
    title: "Tasks",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.APPLICATIONS,
    title: "Copilots",
    displayBackButton: false,
  },
  {
    route: `${PAGE_URL.GENERATIVE_AI_TASKS}${PAGE_URL.AUTOCOMPLETE}`,
    title: "Autocomplete",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.HEIDIS_INSIGHT}`,
    title: "Hedis Insights",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_NOTE_CLASSIFICATION}`,
    title: "Clinical Note Classification",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_EXTRACTION}`,
    title: "Clinical Entity Extraction w/ Negation",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_NORMALIZATION}`,
    title: "Clinical Entity Normalization to UMLS/codes",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_SUMMARY}`,
    title: "Clinical Protocol Patient Summary",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_ICEC_SUMMARY}`,
    title: "Clinical Protocol IE Criteria Simplfication",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.PHI_REDACTION}`,
    title: "PHI Redaction",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.LABORATORY_RESULT}`,
    title: "Laboratory Result",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.FORM_2728_PROCESSOR}`,
    title: "CMS 2728 Form Extraction",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.REALTION_EXTRACTION}`,
    title: "Relation Extraction (DDI, Chem-Protein)",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.SEARCH_AND_DATA_MINING_TASKS}${PAGE_URL.DOCUMENT_QUESTION_ANSWERING}`,
    title: "Document Question Answering",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.GENERATIVE_AI_TASKS}${PAGE_URL.GENERATIVE_QA}`,
    title: "Generative QA",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DISEASE_SPECIFIC_INSIGHTS}${PAGE_URL.HEMOPHILIA_QUESTION_ANSWERING}`,
    title: "Hemophilia - Biomedical Question Answering",
    displayBackButton: true,
  },
  {
    route: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.LABORATORY_RESULT}`,
    title: "Creatinine Processor",
    displayBackButton: true,
  },
  { route: PAGE_URL.EVIDENCE_VIEWER, title: "Evidence Viewer", displayBackButton: true },
{ route: PAGE_URL.DOCUMENT_INSIGHTS, title: "Document Insights", displayBackButton: true },
];
