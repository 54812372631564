import React from "react";
import {
  DATA_EXTRACTION_AND_NORMALIZATION_TASKS,
  DISEASE_SPECIFIC_INSIGHTS_TASKS,
  GENERATIVE_AI_TASKS,
  KNOWLEDGE_MANAGEMENT_TASKS,
  SEACRH_AND_DATA_MINING_TASKS,
} from "../../utils";
import { TaskCard } from "./tasks-container";
import { Button } from "antd";
import { CLINICAL_RESEARCH_TASKS } from "../../utils/constants/ClinicalResearchTasks";

export const TasksContainer = () => {
  return (
    <>
      <div className="flex jcsb aic">
        <div></div>
        <Button className="fill">Create New Task</Button>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Data Extraction & Normalization</p>
        <div className="task-card-grid">
          {DATA_EXTRACTION_AND_NORMALIZATION_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Clinical Research</p>
        <div className="task-card-grid">
          {CLINICAL_RESEARCH_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Disease Specific Insights</p>
        <div className="task-card-grid">
          {DISEASE_SPECIFIC_INSIGHTS_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Search & Data Mining</p>
        <div className="task-card-grid">
          {SEACRH_AND_DATA_MINING_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Knowledge Management</p>
        <div className="task-card-grid">
          {KNOWLEDGE_MANAGEMENT_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
      <div>
        <p className="task-card-heading m-t m-b">Generative AI</p>
        <div className="task-card-grid">
          {GENERATIVE_AI_TASKS.map((task, index) => (
            <TaskCard {...task} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};