import { PAGE_URL } from "./routes";

export const DATA_EXTRACTION_AND_NORMALIZATION_TASKS: ITask[] = [
  {
    title: "Clinical Entities Extraction w/ Negation",
    description:
      "This task extracts named clinical entities, including diseases, signs/symptoms, procedures, anatomy, and medications from text",
      link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_EXTRACTION}`,
  },
  {
    title: "Clinical Entity Normalization to UMLS/codes",
    description: "Links named entities to UMLS/codes- medical conditions/ICD-10, Medications/RxNorm, Test-treatment procedure/CPT from text",
    link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_NORMALIZATION}`,
  },
  {
    title: "Relation Extraction tasks from text",
    description: "Identify relations in natural language within a body of text",
    link: "",
  },
  {
    title: "Entity Resolution (Patient, Drugs, Disease)",
    description: "Identify and resolve entities from body of text and associate like entities",
    link: "",
  },
  {
    title: "Negation Detection",
    description: "Identify negation clauses from sentences",
    link: "",
  },
  {
    title: "Detect Time expression",
    description: "Identify time expression relationships within text",
    link: "",
  },
  {
    title: "Relation Extraction (DDI, Chem-Protein)",
    description: "Identify drug-drug, drug-disease or chemical-protein interactions from text",
    link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.REALTION_EXTRACTION}`,
  },
  {
    title: "Gene mapping",
    description: "Identify gene expressions and mutations from text or reports",
    link: "",
  },
  {
    title: "Document Section Identification",
    description: "Document comprehension to understand sections, details and layout",
    link: "",
  },
  {
    title: "CMS 2728 Form Extraction",
    description: "Extracts dialysis treatment status from CMS reports. This can be used to identify patients who are receiving dialysis treatment, as well as to track changes in their treatment status",
    link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.FORM_2728_PROCESSOR}`,
  },
  {
    title: "Creatinine Processor",
    description: "Extracts the Creatinine value from Clinical Lab reports, which can be used to monitor kidney function and diagnose and manage conditions.",
    link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.LABORATORY_RESULT}`,
  },
  {
    title: "PHI Redaction",
    description: "Accepts image (*.tiff, *.png) detects and removes PHI.",
    link: `${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.PHI_REDACTION}`,
  },
];
