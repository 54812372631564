import React, { useCallback, useState } from "react";
import { SINGLE_TAB } from "../../utils";
import { Button, message } from "antd";
import { FileUpload } from "..";
import { TaskInputTabs } from "./TaskInputTabs";
import { InputText, ErrorState } from "./InputText";
import { TaskSingleTabs } from "../tasks/TaskSingleTabs";

const allowedTypes = [".txt", ".pdf", ".doc"];

const validateFile = (file: any) => {
  if (!file) {
    throw new Error("No file selected.");
  } else {
    const extension = file.name.substring(file.name.lastIndexOf("."));
    if (!allowedTypes.includes(extension)) {
      throw new Error("Invalid file type.");
    }
  }
};

interface Props {
  isLoading: boolean;
  query: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export const TaskInput = ({ setIsLoading, isLoading, query, setQuery }: Props) => {
  const [error, setError] = useState<ErrorState>({
    textInput: "",
  });

  const [inputText, setInputText] = useState("");
  const [selectedTab, setSelectedTab] = useState(SINGLE_TAB.ENTER_TEXT);
  const [fileList, setFileList] = useState([] as { uid: string; name: string }[]);

  const handleFileChange = useCallback((info: any) => {
    try {
      if (info.fileList.length > 1) {
        info.fileList.shift();
      }
      const selectedFile = info.fileList[0];
      if (selectedFile) {
        const inputFile = info.fileList[0].originFileObj;
        validateFile(inputFile);
        const reader = new FileReader();
        reader.onload = async (event) => {
          setInputText(event.target?.result as string);
        };
        reader.readAsText(info.file.originFileObj);
        setFileList([{ uid: inputFile.uid, name: inputFile.name }]);
      }
    } catch (e: any) {
      message.error(e.message);
    }
  }, []);

  const handleRemoveFile = () => {
    setFileList([]);
    setInputText("");
  };

  const onInputTextChange = useCallback(
    (e: any) => {
      setError({ ...error, textInput: "" });
      setInputText(e.target.value);
    },
    [error, setInputText]
  );

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  const validateForm = () => {
    const err: ErrorState = {
      textInput: "",
    };
    let isValidForm = false;
    if (!inputText) err.textInput = "This field is required";
    if (!err.textInput) isValidForm = true;
    setError(err);
    return isValidForm;
  };

  const handleSubmit = async () => {
    setError({ textInput: "" });
    const isValid = validateForm();
    if (isValid) {
      if (query === inputText) {
        setQuery(inputText + " ");
      } else {
        setQuery(inputText);
      }
      setIsLoading(true);
    }
  };
  return (
    <>
      <div className="m-b">
        <TaskSingleTabs {...{ onTabChange, selectedTab }} />
        {selectedTab === SINGLE_TAB.ENTER_TEXT ? (
          <>
            <p className="m-b">Enter/Copy paste text</p>
            <InputText {...{ onInputTextChange, error, inputText }} />
          </>
        ) : null}
      </div>
      <div className="tr">
        <Button className="outline" loading={isLoading} onClick={handleSubmit}>
          Perform Task
        </Button>
      </div>
    </>
  );
};
