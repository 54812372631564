import React, { useCallback, useState } from 'react';
import TasksApi from "../../api/tasks.api";
import { Button, Upload, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
    EVIDENCE_VIEWER_URL,
  } from "./tile-helper-components";
import { INSIGHT_TYPE, KeyValueMap } from '../evidence-viewer/evidence-viewer-container';
import { EvidencePageResponseInfo } from '../../api';
import AWS from 'aws-sdk';
import { PageWrapper, EvideceViewerAside, EvidenceViewerContainer } from '../';
import { CohortCollapseIcon } from '../../assets/icons';

import { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_SECRET_ACCESS_KEY, REACT_APP_S3_BUCKET_NAME } from '../../utils/constants/constants';


AWS.config.update({
    region: REACT_APP_AWS_REGION,
    accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const categoriesMapping: KeyValueMap = {
    //MEDICAL_CONDITION: "#6077E1",
    //MEDICATION: "#00CACD",
    TEST_TREATMENT_PROCEDURE: "#9B67C6",
    PROTECTED_HEALTH_INFORMATION: "#95CB7A",
    // CMS_2728_FORM: "#E52337"
    //ANATOMY: "#E8AE3D",
  };

  interface Props {
    isContainerCollapsed: boolean;
    setIsContainerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LabResultContainer = (
    {
        isContainerCollapsed,
        setIsContainerCollapsed
    }: Props
) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [resultImage, setResultImage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState<any | ''>('');
    const [evidenceContentLoading, setEvidenceContentLoading] = useState(false);
    const [receivedLambdaResponse, setReceivedLambdaResponse] = useState<EvidencePageResponseInfo[] | null>(null);
    const [currPage, setCurrPage] = useState(1);



    const handleSearchNavigate = (event: any) => {
        const str="Cough"
        const patientId ="38"
        navigate(
          `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`
        );
      };


    const handleFileChange = useCallback((info: any) => {
        try {
            if (info.fileList.length > 1) {
                info.fileList.shift();
            }
            const file = info.fileList[0];
            if (file) {
                setSelectedFile(file.originFileObj);
                setPdfUrl('');
                setReceivedLambdaResponse(null);
                setCurrPage(1);
            }
        } catch (e: any) {
            message.error(e.message);
        }
    }, []);

    const generateS3PreSignedUrl = async (objectKey: string): Promise<string> => {
        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME ? process.env.REACT_APP_S3_BUCKET_NAME : 'autonomize-processing-sprint',
            Key: objectKey,
            Expires: 30 * 60
        };
        const url = s3.getSignedUrlPromise('getObject', params);
        return url
    }

    const uploadFileToS3 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const params: AWS.S3.PutObjectRequest = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME ? process.env.REACT_APP_S3_BUCKET_NAME : 'autonomize-processing-sprint',
                Key: file.name,
                Body: file,
                ContentType: "application/pdf",
                ContentDisposition: `form-data; name="file"; filename="${file.name}"`
            };

            s3.upload(params, (err: any, data: AWS.S3.ManagedUpload.SendData) => {
                if (err) {
                    console.error('Error uploading file:', err);
                    reject(err);
                } else {
                    resolve(data.Location);
                }
            });
        });
    };


    const handleUpload = async () => {
        if (selectedFile) {
            setIsLoading(true);
            try {
                // Use a Promise to wait for the upload to finish
                await new Promise<void>((resolve, reject) => {
                    uploadFileToS3(selectedFile)
                        .then((dataLocation) => {
                            resolve(); // Resolve the promise when the upload is successful
                        })
                        .catch((err) => {
                            console.error('Error uploading file:', err);
                            reject(err); // Reject the promise in case of an error
                        });
                });
                await new Promise<void>((resolve, reject) => {
                    generateS3PreSignedUrl(selectedFile.name)
                        .then((pdfS3Url) => {
                            setPdfUrl(pdfS3Url);
                            resolve(); // Resolve the promise when the upload is successful
                        })
                        .catch((err) => {
                            console.error('Error uploading file:', err);
                            reject(err); // Reject the promise in case of an error
                        });
                });
                setEvidenceContentLoading(true);
                const cmsResponse: any = await new TasksApi().laboratoryResult(selectedFile.name);
                if (cmsResponse) {
                    setReceivedLambdaResponse(cmsResponse.data.results as unknown as EvidencePageResponseInfo[]);
                }
            } catch (error) {
                console.error('Error processing file:', error);
            } finally {
                setIsLoading(false);
                setEvidenceContentLoading(false);
            }
        }
    };


    const handleRemoveFile = () => {
        setSelectedFile(null);
        setPdfUrl('');
        setReceivedLambdaResponse(null);
        setCurrPage(1);
    };

    return (
        <div style={{display: 'grid'}}>
            <div style={{display: 'flex', cursor: 'pointer'}}  onClick={() => { setIsContainerCollapsed(!isContainerCollapsed) }} >
                <CohortCollapseIcon className={`${isContainerCollapsed ? "rotate-icon" : ""} collapse-pointer-icon`} fill='#3232A6'/>
                <p style={{marginLeft: "1em", color: '#3232A6'}}>{isContainerCollapsed ? "Show Left Panel": "Hide Left Panel"}</p>
            </div>
            <div style={{ "width": '50%', float: 'left' }}>
                <div className="browse-file m-b">
                    <br/>
                    <Upload accept='application/pdf' onChange={handleFileChange} onRemove={handleRemoveFile}>
                        <Button className="outline">
                            Browse File
                        </Button>
                    </Upload>
                </div>
                <Button className="outline" loading={isLoading} onClick={handleUpload} disabled={selectedFile ? false : true}>
                    Perform Task
                </Button><br /><br />
            </div>
            <br/>
            {receivedLambdaResponse && pdfUrl ? (<PageWrapper
                pageContainerChildren={
                    <EvidenceViewerContainer
                        {...{
                            currPage,
                            feedBackEnabled: false,
                            pdfViewerEnabled: true,
                            evidenceContentLoading: isLoading || evidenceContentLoading,
                            pageData: receivedLambdaResponse[currPage - 1],
                            pdfUrl,
                            setCurrPage,
                            categoriesMapping
                        }}
                    />
                }
            />) :
                (<div>Upload the PDF to get response</div>)}
        </div>
    );
};