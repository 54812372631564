import React, { useCallback, useState } from 'react';
import TasksApi from "../../api/tasks.api";
import { Button, Upload, message } from "antd";
import "./PHIRedaction.scss";
import { triggerBase64Download } from 'react-base64-downloader';

export const PHIRedactionContainer = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [resultImage, setResultImage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = useCallback((info: any) => {
        setResultImage(null)
        try {
            if (info.fileList.length > 1) {
                info.fileList.shift();
            }
            const file = info.fileList[0];
            if (file) {
                setSelectedFile(file.originFileObj);
            }
        } catch (e: any) {
            message.error(e.message);
        }
    }, []);

    const handleUpload = async () => {
        if (selectedFile) {
            setIsLoading(true)
            try {
                const reader = new FileReader();
                reader.readAsDataURL(selectedFile);

                reader.onload = async () => {
                    const base64Image = reader.result?.toString().split(',')[1]
                    try {
                        const response = await new TasksApi().phiRedaction(base64Image);
                        setResultImage(response.data.results);
                        setIsLoading(false);
                    } catch (error) {
                        console.error('Error redacting image:', error);
                        setIsLoading(false);
                    }
                };
            } catch (error) {
                console.error('Error reading image:', error);
                setIsLoading(false);
            }
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    return (
        <div>
            <div style={{ "width": '50%', float: 'left' }}>
                <div className="browse-file m-b">
                    <br/>
                    <Upload accept='image/*' onChange={handleFileChange} onRemove={handleRemoveFile}>
                        <Button className="outline">
                            Browse File
                        </Button>
                    </Upload>
                </div>
                <Button className="outline" loading={isLoading} onClick={handleUpload}>
                    Perform Task
                </Button><br /><br />
            </div>
            <div style={{ "width": '50%'}}>
                <h1 className="m-b">Result</h1><br />
                {resultImage ? (<img src={`data:image/png;base64,${resultImage}`} alt="Result" style={{ maxWidth: '170%' }} />) : (
                    <div>Please upload image to get PHI redacted image.</div>
                )}
            </div>
            <div>
                { resultImage ?
                    
                   ( <Button className='ant-btn ant-btn-default fill' style={{ float: 'right'}} onClick={ () => {triggerBase64Download(`data:image/png;base64,${resultImage}`, selectedFile?.name.split('.')[0]) }}>
                    Download
                    </Button>
                   ): <></>
                }
                
            </div>
        </div>
    );
};