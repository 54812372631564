import { openNotificationWithIcon } from "../notification";

export enum USER_ROLES_CODE {
  ADMIN = "SUPER_ADMIN",
  DATA_SCIENTIST = "DATA_SCIENTIST",
  BUSINESS_USER = "BUSINESS_USER",
}

export enum DOCUMENT_STATUS {
  FILE_UPLOADED = "File Uploaded",
  // DATA_EXTRACTION_STARTED = "Data Extraction Started",
  FILE_FORMATTING_AND_OCR = "File Formatting & OCR",
  OCR_COMPLETE = "OCR - Completed",
  CLINICAL_DATA_EXTRACTION_STARTED = "Clinical Data Extraction Started",
  COMPLETED = "SUCCEEDED",
  ERROR = "ERROR",
}

export enum MODAL_TYPE {
  ADD_EDIT_PROJECT = "addEditProject",
  DELETE_CONFIRMATION = "deleteConfirmation",
  EDIT_PROFILE = "editProfile",
  FORGOT_PASSWORD = "forgotPassword",
  ADD_AND_SIGN_UP_USER = "addAndSignUpUser",
  APPROVE_CONFIRMATION = "approveConfirmation",
  ADD_PROJECT = "addProject",
  CREATE_PIPELINE = "createPipeline",
  ADD_ROLE = "addRole",
  ADD_GROUP = "addGroup",
  FEEDBACK = "feedback",
  ADD_COPILOT = "addCopilot",
  DOCUMENT_PROCESSING_DETAIL_STATUS = "documentProcessingDetailStatus",
  PAGES_STATUS = "pagesStatus",
  VIEW_FULL_SOURCE = "viewFullSource"
}
export const DATA_ADMIN_PERMISSIONS = ["03", "05"];

export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum TASK_COLLAPSIBLE_COMPONENT_HEADING {
  TASK_DESCRIPTION = "Task Description",
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export const downloadPDF = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    openNotificationWithIcon("", "Downlaod Failed", "error");
  }
};